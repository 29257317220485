<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>


<v-row justify="center" style="padding-top:20px">
    
  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher un Produit"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  > 
<template v-slot:item.final="{ item }">
        {{ formatNumber(item.initial-item.sale+item.reception+item.other+item.ajustement) }}
    </template>
  </v-data-table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import numeral from 'numeral'

export default {
  name: 'StockView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/reports/stock?include=product&from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          self.rows = response.data.result
          self.enable = true          
        })
    },
    formatNumber: function(cost){
      return numeral(cost).format('0,0.00')
    },
  },
  mounted(){
    this.load()
  },
  data: () => ({
      search: '',
      loading: false,
      items: [
        {
          text: 'RAPPORT OPERATIONS',
          disabled: false,
          href: '/stock',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: 'CATEGORIE',
            align: 'start',
            sortable: false,
            value: 'category',
          },
          { text: 'PRODUIT', value: 'product_name' },
          { text: 'INITIAL', value: 'initial' },
          { text: 'VENTES', value: 'sale' },
          { text: 'RECEPTIONS', value: 'reception' },
          { text: 'AJUSTEMENTS', value: 'ajustement' },
          { text: 'AUTRES', value: 'other' },
          { text: 'STOCK FINAL', value: 'final' },
        ],
        rows: [],
        roles: [],
        dialog:'',
        dialogDelete: false,
        stock : {
          product_id: '',
          user_id: '',
          cost: '',
          id:'',
          memo: '',
          type: 1,
          quantity: '',
        }
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .v-application--wrap .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .v-dialog button.v-btn{
    margin-right:8px!important;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:18px;
  }

   .v-application .v-dialog .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:0px!important;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }c
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>